import logger from '@/utils/logger';
import { Error } from '@/providers/cbr/cbr.types';
import {
  CBRMemberFavouritesResponse,
  CBRDeleteFavouriteResponse,
  CBRInsertFavouriteResponse,
  FavouritePartnerProperties,
  CBRGetFavouriteForUserResponse,
  AccountBalanceRequest,
} from './user.types';
import CBRProvider from '../../providers/cbr';
import GlpProvider from '../../providers/glp';
import {
  getUserFavouriteQuery,
  deleteFavouriteQuery,
  insertFavouriteQuery,
  getUserFavouritesQuery,
} from './user.query';
import { getCBRProgramID } from '../market/market.types';

const generateCbrUserProviderErrorResponse = (errors: Error[]) => {
  logger.error("There were errors trying to fetch user's favourites.", {
    errors,
  });

  return { error: 'There was an issue with the favourites provider.' };
};

const User = {
  async getFavouritePartners({
    market,
    accessToken,
    membershipId,
  }: FavouritePartnerProperties) {
    const provider = new CBRProvider(market, accessToken);
    const response = await provider.makeCBRRequest<CBRMemberFavouritesResponse>(
      {
        query: getUserFavouritesQuery,
        variables: {
          memberId: membershipId,
        },
      },
    );

    if (response.errors) {
      return generateCbrUserProviderErrorResponse(response.errors);
    }

    return {
      data:
        response.data.member.find(
          ({ program_member_reference }) =>
            program_member_reference === membershipId,
        ) ?? [],
    };
  },

  async partnerIsUserFavorite({
    market,
    accessToken,
    membershipId,
    mechanicId,
  }: FavouritePartnerProperties): Promise<boolean> {
    const provider = new CBRProvider(market, accessToken);
    const response =
      await provider.makeCBRRequest<CBRGetFavouriteForUserResponse>({
        query: getUserFavouriteQuery,
        variables: {
          memberId: membershipId,
          mechanicId,
        },
      });

    return response.data.favourite.length > 0;
  },

  async addFavouritePartner({
    market,
    accessToken,
    membershipId,
    mechanicId,
  }: FavouritePartnerProperties) {
    const provider = new CBRProvider(market, accessToken);
    const response = await provider.makeCBRMutation<CBRInsertFavouriteResponse>(
      {
        query: insertFavouriteQuery,
        variables: {
          memberId: membershipId,
          mechanicId,
          programId: getCBRProgramID[market.opCoId],
        },
        headers: { 'CBR-MEMBER-ID': membershipId as string },
      },
    );

    if (response.errors) {
      return generateCbrUserProviderErrorResponse(response.errors);
    }

    const [insertedFavourite] = response.data.insert_member.returning;
    return {
      data: insertedFavourite?.favourites?.filter(
        x => x.mechanic_id === mechanicId,
      ),
    };
  },

  async removeFavouritePartner({
    market,
    accessToken,
    membershipId,
    favouriteId,
  }: FavouritePartnerProperties) {
    const provider = new CBRProvider(market, accessToken);
    const response = await provider.makeCBRMutation<CBRDeleteFavouriteResponse>(
      {
        query: deleteFavouriteQuery,
        variables: {
          memberId: membershipId,
          favouriteId,
        },
        headers: { 'CBR-MEMBER-ID': membershipId as string },
      },
    );

    if (response.errors) {
      return generateCbrUserProviderErrorResponse(response.errors);
    }

    const { returning } = response.data.delete_favourite;
    const deletedFavourite = returning.find(
      ({ favourite_id }) => favourite_id === Number(favouriteId),
    );

    if (deletedFavourite) return { data: deletedFavourite };
    return { error: `Favourite with id ${favouriteId} was not deleted.` };
  },

  async getAviosBalance({
    auth0accessToken,
    membershipId,
    programId,
  }: AccountBalanceRequest) {
    const provider = new GlpProvider();
    return provider.getAccountDetails(
      auth0accessToken,
      membershipId,
      programId,
    );
  },
};

export default User;

import getEnvironmentVariable from '@/utils/get-environment-variable';
import { AUDIENCES } from './auth0.types';

export const LAST_AUDIENCE = AUDIENCES.API_AVIOS;

export const sortedAudiencesWithApiAviosLast: AUDIENCES[] = [
  ...(Object.keys(AUDIENCES) as AUDIENCES[]).filter(
    name => name !== LAST_AUDIENCE,
  ),
  LAST_AUDIENCE,
];

export const FIRST_AUDIENCE = sortedAudiencesWithApiAviosLast[0];

export const audienceUrls = {
  [AUDIENCES.API_AVIOS]: 'https://api.avios.com/',
  [AUDIENCES.ACT]: 'https://api.avios.com/v1/act/',
  [AUDIENCES.FS]: 'https://api.avios.com/v1/fs/',
};

export const urlsToAudience = Object.fromEntries(
  Object.entries(audienceUrls).map(([name, url]) => [url, name as AUDIENCES]),
);

export const audienceScopes = {
  [AUDIENCES.API_AVIOS]:
    'openid profile email offline_access read:account read:collection_mechanic read:collection_offers read:collection_categories read:member_favourite write:member_favourite read:collection_partners read:next_best_acquisition',
  [AUDIENCES.ACT]:
    'openid profile email offline_access read:member:qrcode read:member_transactions',
  [AUDIENCES.FS]:
    'openid profile email offline_access read:acquisition:next_best',
};

export const SILENT_LOGIN_FLAG_EXPIRY_TIME_IN_SECONDS = 60;

export const userAgentsSubstringToSkipSilentLogin = () =>
  getEnvironmentVariable(
    'COMMA_SEPARATED_USER_AGENTS_TO_SKIP_SILENT_LOGIN',
  ).split(',');

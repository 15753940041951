import { SectionFieldsFragment } from './contentful.types';

export const filteredMerchantsFields = `fragment filteredMerchantsFields on FilteredMerchants {
	sys {
		id
	}
	contentType: __typename
	listType
	tileType
	includedCategories
}`;

export const merchantListFields = `fragment merchantListFields on MerchantList {
	contentType: __typename
	listType
	merchants
	sys {
		id
	}
	tileType
	partnerVariant
}`;

export const articleStoryFields = `fragment articleStoryFields on ArticleStory {
	contentType: __typename,
	sys {
		id
	}
	htmlId
	image {
	title
	url
	},
	imageAlt,	
	youtubeId
	youtubeTitle
	youtubeAutoPlay
	content {
		...titleCopyCtaBlockFields
	}
}`;

export const titleCopyCtaBlockFields = `fragment titleCopyCtaBlockFields on TitleCopyCtaBlock {
	contentType: __typename
	sys {
		id
	}
	htmlId
	name
	headingOne
	headingOneColor
	headingOneTextAlign
	headingTwo
	headingTwoColor
	headingTwoTextAlign
	headingThree
	headingThreeColor
	headingThreeTextAlign
	headingFour
	headingFourColor
	headingFourTextAlign
	description
	descriptionColour
	descriptionTextAlign
	overrideDescriptionFontSize
	linkPrimaryLabel
	linkPrimaryUrl
	linkSecondaryLabel
	linkSecondaryUrl
	primaryCta {
		url
		label
	}
	secondaryCta {
		url
		label
	}
	openLinksInNewTab
	openCtasInNewTab
	inlineButtons
}
`;

export const versatileCardFields = `fragment versatileCardFields on VersatileCard {
	contentType: __typename,
	name,
	sys {
		id
	}
	htmlId
	backgroundColor
	image {
		title,
		url
		description
	},
	imagePosition,
	imageHeight,
	imageMobileHeight,
	imageWidth
	imageContained,
	imageOptimised {
		largeImage {
		  url
		  title
		  description
		}
		smallImage {
		  url
		}
	},
	headingOne,
	headingTwo,
	headingThree,
	headingFour,
	headingFive,
	bodyContent,
	spaceBetweenHeadingAndBody,
	textAlign,
	textColor,
	cardIsPrimaryLink,
	linkPrimary,
	linkLabelPrimary,
	linkSecondary,
	linkLabelSecondary,
	ctaFullWidth,
	imageButtonPrimary {
		url
		description
		height
		width
	}
	imageButtonPrimaryUrl
	imageButtonSecondary {
		url
		description
		height
		width
	}
	imageButtonSecondaryUrl
	youtubeId
	youtubeVideoHeight
	youtubeTitle
	youtubeAutoPlay
	partner
	partnerVariant
	partnerTextSize
	partnerLogoSize
	partnerSeparatorLine
	showPartnerFavouriteToggle
	openLinksInNewTab
	openCtasInNewTab
	borderless
	buttonsCollection(limit:3)  {
		items {
			contentType: __typename
			sys {
				id
			  }
			 label
			 variant
			 url
			 alignIcon
			 iconName
			 size
			 openLinksInNewTab
			 image {
			   url
			   title
			   description
			   width
			   height
			 }
	   }
	}
}`;

export const dynamicVersatileCardFields = `fragment dynamicVersatileCardFields on DynamicVersatileCard {
	contentType: __typename,
	sys {
		id
	}
	title,
	externalSource
	maximumNumberOfCards
}`;

export const superHeroFields = `fragment superHeroFields on Superhero {
	contentType: __typename,
sys {
  id
}
titleCopyCtaBlock {
  ...titleCopyCtaBlockFields
}
heroImage {
  largeImage {
	url
	title
	description
  }
  smallImage {
	url
  }
}
imageHeight
imageObjectPosition
partner
youtubeId
youtubeTitle
youtubeAutoPlay
youtubeVideoHeight
titleCopyCtaBlockJustifyContent
titleCopyCtaBlockJustifyContent
titleCopyCtaBlockAlignItems
titleCopyCtaBlockMaxWidth
titleCopyCtaBlockPaddingTop
titleCopyCtaBlockPaddingBottom
titleCopyCtaBlockPaddingLeft
titleCopyCtaBlockPaddingRight
opacityRgba
}`;

export const accordionFields = `fragment accordionFields on Accordion {
	contentType: __typename
	name
	sys {
	  id
	}
	title
	body
	isOpen
}`;

export const financialServicesBannerFields = ` fragment financialServicesBannerFields on FinancialServicesBanner {
	contentType: __typename
	name
	sys {
		id
	}
	fsCategory
	fsCategoryTier
	bannerContent {
		...versatileCardFields
	}
}`;

export const buttonFields = `fragment buttonFields on Button {
	contentType: __typename
	sys {
		id
	  }
	 label
	 variant
	 url
	 alignIcon
	 iconName
	 size
	 openLinksInNewTab
	 iconColor
	 textColor
	 textSize
	 image {
	   url
	   title
	   description
	   width
	   height
	 }
}`;

export const memberGetMemberFields = `fragment memberGetMemberFields on MemberGetMember {
	contentType: __typename
	name
	ariaLabel
	termsLabel
	termsContent
	termsExtraContent
}`;

export const buildSectionFields = ({
  fieldName,
  contentCollectionLimit,
  contentRowItemsLimit,
  versatileCard = false,
  dynamicVersatileCard = false,
  titleCopyCtaBlock = false,
  articleStory = false,
  merchantList = false,
  filteredMerchants = false,
  superHero = false,
  accordian = false,
  financialServicesBanner = false,
  button = false,
  memberGetMember = false,
  card = false,
  textBlock = false,
  carouselFavourites = false,
}: SectionFieldsFragment) => `fragment ${fieldName} on Section {
	contentType: __typename
	sys {
		id
	}
	ariaLabel
	visibility
	fsCategory
	fsCategoryTierOptions
	backgroundColor
	isFullBleed
	paddingTop
	paddingBottom
	backgroundImage {
		title
		url
	}
	contentCollection (limit: ${contentCollectionLimit}) {
		items {
		... on LayoutContainer {
			contentType: __typename
			sys {
				id
			}
			variant
			layout
			alignContent
			componentsCollection(limit: 8) {
				items {
					${card ? '...cardFields' : ''}
					${textBlock ? '...textBlockFields' : ''}
				}
			}
		}
		... on ContentRow {
		contentType: __typename
		sys {
			id
		}
		isRow
		alignContent
		isCarousel
		carouselAutoPlay
		carouselPresentation
		extraPaddingBottom
		contentSeparators
			... on ContentRow {
				itemsCollection(limit: ${contentRowItemsLimit}) {
					items {
						${dynamicVersatileCard ? '...dynamicVersatileCardFields' : ''}
						${versatileCard ? '...versatileCardFields' : ''}
						${titleCopyCtaBlock ? '...titleCopyCtaBlockFields' : ''}
						${articleStory ? '...articleStoryFields' : ''}
						${merchantList ? '...merchantListFields' : ''}
						${filteredMerchants ? '...filteredMerchantsFields' : ''}
						${superHero ? '...superHeroFields' : ''}
						${accordian ? '...accordionFields' : ''}
						${financialServicesBanner ? '...financialServicesBannerFields' : ''}
						${button ? '...buttonFields' : ''}
						${memberGetMember ? '...memberGetMemberFields' : ''}
						${card ? '...cardFields' : ''}
						${carouselFavourites ? '...carouselFavouritesFields' : ''}
					}
				}
			}
		}
	}
}
}`;

export const textBlockFields = `fragment textBlockFields on ComponentTextBlock {
	sys {
		id
	}
	htmlId
	contentType: __typename
  	internalName
	eyebrowLabel
  	pillLabel
  	heading
  	headingTag
  	headingSize
	body
	headingTextAlign
	bodyTextAlign
	buttonsCollection(limit:2)  {
		items {
			contentType: __typename
			sys {
				id
			  }
			 label
			 variant
			 url
			 alignIcon
			 iconName
			 size
			 openLinksInNewTab
			 image {
			   url
			   title
			   description
			   width
			   height
			 }
	   }
	}
}`;

export const cardFields = `fragment cardFields on ComponentCard {
	contentType: __typename
  sys {
    id
  }
  image {
    title
    url
    description
  }
  textBlock {
    ...textBlockFields
  }
  icon
  textColour
  backgroundColour
  variant
  cardLink
  partner
}`;

export const heroFields = `fragment heroSectionFields on ComponentSearch {
	contentType: __typename
	sys {
		id
	}
	image {
		title
		url
		description
	}
	searchButtonLabel
	noResultsLabel
	enableSearch
	backgroundColour
	textBlock {
		...textBlockFields
	  }
}
${textBlockFields}`;

export const carouselFavouritesFields = `fragment carouselFavouritesFields on ComponentCarouselFavourites {
	sys {
		id
	}
	contentType: __typename
	tabRecentlyViewed
	tabYourFavourites
	seeAllText
	seeAllRecentlyViewedLink
	seeAllYourFavouritesLink
	tabRecentlyViewedEmptyText
	tabYourFavouritesEmptyText
}`;

import NextImage from '@/components/data-display/image';
import {
  SwitchTab,
  SwitchTabItem,
} from '@/components/integrated/switch-tab/switch-tab';
import useHandleFavourites from '@/hooks/use-handle-favourites';
import useVisitHistoryLoadMore from '@/hooks/use-visit-history-load-more';
import { CustomLink } from '@/components/custom-link';
import Loader from '@/components/feedback/loader';
import Container from '@/components/integrated/container';
import {
  CONTAINER_ALIGN_CONTENT,
  CONTAINER_ROW_LAYOUT,
  CONTAINER_VARIANT,
} from '@/components/integrated/container/container.types';
import Button from '@/components/inputs/button/button';
import { VARIANT } from '@/components/data-display/icon/icon.types';
import {
  VARIANT as BUTTON_VARIANT,
  SIZE,
} from '@/components/inputs/button/button.types';

import {
  CarouselFavouritesRecentlyViewedProperties,
  ContainerListProperties,
  ImageCardType,
} from '@/modules/carousel-favourites-recently-viewed/carousel-favourites-recently-viewed.types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ClicksData } from '@/providers/collinson/collinson.types';
import styles from './carousel-favourites-recently-viewed.module.scss';

const ImageCard = ({ image, name, destinationUrl }: ImageCardType) => (
  <CustomLink href={destinationUrl} className={styles['carousel__image-card']}>
    <div className={classNames(styles['carousel__image-card__logo-wrapper'])}>
      <NextImage
        crossOrigin="anonymous"
        src={image.logoUrl ?? '/images/other/broken-image.png'}
        isLocal={!image.logoUrl}
        className={classNames(
          styles['carousel__image-card__logo-wrapper__logo'],
        )}
        alt={image.alt ?? name}
        fill
      />
    </div>

    <p className={styles['carousel__image-card__name']}>{name}</p>
  </CustomLink>
);

const ContainerList = ({
  seeAllText,
  seeAllLink,
  childrenSize = 0,
  emptyListText,
  children,
}: ContainerListProperties) => {
  const showSeeAll = childrenSize > 6;
  const showEmptyDescription = childrenSize === 0;

  if (showEmptyDescription) {
    return (
      <div className={styles['carousel__empty-list']}>
        <p>{emptyListText}</p>
      </div>
    );
  }

  return (
    <Container
      variant={CONTAINER_VARIANT.ROW}
      layout={CONTAINER_ROW_LAYOUT.ONE_ROW}
      alignContent={CONTAINER_ALIGN_CONTENT.CENTER}
    >
      {children}
      {showSeeAll && (
        <div className={styles['carousel__see-all']}>
          <Button
            icon={VARIANT.MoreHorizontal}
            as="link"
            href={seeAllLink}
            variant={BUTTON_VARIANT.Custom}
            className={styles['carousel__see-all__button']}
            size={SIZE.xxxLarge}
          />
          <p className={styles['carousel__see-all__text']}>{seeAllText}</p>
        </div>
      )}
    </Container>
  );
};

const CarouselFavouritesRecentlyViewed = ({
  tabRecentlyViewed,
  tabYourFavourites,
  seeAllText,
  seeAllYourFavouritesLink,
  seeAllRecentlyViewedLink,
  tabRecentlyViewedEmptyText,
  tabYourFavouritesEmptyText,
}: CarouselFavouritesRecentlyViewedProperties) => {
  const { favouritesList = [], isLoading: isLoadingFavourites } =
    useHandleFavourites();
  const { data: recentlyViewedList = [], isLoading: isLoadingRecentlyViewed } =
    useVisitHistoryLoadMore({ limitDefault: 50 });

  const [uniqueRecentlyViewedList, setUniqueRecentlyViewedList] = useState<
    ClicksData[]
  >([]);

  useEffect(() => {
    if (recentlyViewedList.length > 0)
      setUniqueRecentlyViewedList(
        [
          ...new Map(
            recentlyViewedList.map(item => [item.merchant_id, item]),
          ).values(),
        ].filter(item => item.partner?.destinationUrl),
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRecentlyViewed]);

  return (
    <SwitchTab activeTabIndex={0}>
      <SwitchTabItem label={tabRecentlyViewed} className={styles.carousel__tab}>
        {isLoadingRecentlyViewed ? (
          <Loader className={styles.carousel__loader} />
        ) : (
          <ContainerList
            seeAllText={seeAllText}
            seeAllLink={seeAllRecentlyViewedLink}
            emptyListText={tabRecentlyViewedEmptyText}
            childrenSize={uniqueRecentlyViewedList.length}
          >
            {uniqueRecentlyViewedList.slice(0, 6).map(partner => (
              <ImageCard
                destinationUrl={
                  partner.partner?.slug
                    ? `/retailers/${partner.partner.slug}`
                    : ''
                }
                key={partner.id}
                name={partner.name}
                image={{
                  logoUrl: partner.partner?.logo,
                  alt: partner.partner?.altLogo,
                }}
              />
            ))}
          </ContainerList>
        )}
      </SwitchTabItem>
      <SwitchTabItem label={tabYourFavourites} className={styles.carousel__tab}>
        {isLoadingFavourites ? (
          <Loader className={styles.carousel__loader} />
        ) : (
          <ContainerList
            seeAllText={seeAllText}
            seeAllLink={seeAllYourFavouritesLink}
            emptyListText={tabYourFavouritesEmptyText}
            childrenSize={favouritesList.length}
          >
            {favouritesList.slice(0, 6).map(partner => (
              <ImageCard
                key={partner.favourite_id}
                destinationUrl={
                  partner.slug ? `/retailers/${partner.slug}` : ''
                }
                name={partner.name}
                image={{
                  logoUrl: partner.logo_url,
                  alt: partner.name,
                }}
              />
            ))}
          </ContainerList>
        )}
      </SwitchTabItem>
    </SwitchTab>
  );
};

export default CarouselFavouritesRecentlyViewed;

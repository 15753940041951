import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import {
  ButtonBodyProperties,
  SIZE,
} from '@/components/inputs/button/button.types';
import {
  CARD_VARIANT,
  TextBlock as TextBlockTypes,
} from '@/components/integrated/card/card.types';
import { LOGO_SIZE } from '@/components/integrated/hero-card/hero-card.types';
import { PartnerTileProperties } from '@/components/integrated/partner-tile/partner-tile.types';
import {
  TEXT_ALIGN,
  TEXT_COLOR,
  VersatileCardImageOptimised,
} from '@/components/integrated/versatile-card/versatile-card.types';
import { YoutubeEmbedBaseProperties } from '@/components/social-media/youtube-embed/youtube-embed.types';
import Market from '@/models/market';
import { Session } from '@auth0/nextjs-auth0';
import {
  CONTAINER_ALIGN_CONTENT,
  CONTAINER_ROW_LAYOUT,
  CONTAINER_VARIANT,
} from '@/components/integrated/container/container.types';
import { ErrorResponse, Response } from '../fetch/fetch.types';

export type ItemRecord = {
  [key: string]: string | string[] | undefined;
} & {
  sys: {
    id: string;
  };
};

export enum CONTENT_TYPES {
  TitleCopyCtaBlock = 'TitleCopyCtaBlock',
  ContentRow = 'ContentRow',
  Container = 'LayoutContainer',
  ArticleStory = 'ArticleStory',
  DynamicVersatileCard = 'DynamicVersatileCard',
  VersatileCard = 'VersatileCard',
  Superhero = 'Superhero',
  Section = 'Section',
  PartnerList = 'MerchantList', // legacy name from Contentful
  FilteredPartners = 'FilteredMerchants', // legacy name from contentful
  Accordion = 'Accordion',
  FinancialServicesBanner = 'FinancialServicesBanner',
  Button = 'Button',
  MemberGetMember = 'MemberGetMember',
  Card = 'ComponentCard',
  TextBlock = 'ComponentTextBlock',
  CarouselFavourites = 'ComponentCarouselFavourites',
}

export type PartnerResponse = Array<{
  name: string;
  slug: string;
  logoSrc: string;
}> | null;

export type TitleCopyCtaBlockResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  name: string;
  htmlId?: string;
  headingOne?: string | null;
  headingOneColor?: string | null;
  headingOneTextAlign?: string | null;
  headingTwo?: string | null;
  headingTwoColor?: string | null;
  headingTwoTextAlign?: string | null;
  headingThree?: string | null;
  headingThreeColor?: string | null;
  headingThreeTextAlign?: string | null;
  headingFour?: string | null;
  headingFourColor?: string | null;
  headingFourTextAlign?: string | null;
  description: string | null;
  descriptionColour: string | null;
  descriptionTextAlign: string | null;
  overrideDescriptionFontSize?: string | null;
  linkPrimaryLabel?: string | null;
  linkPrimaryUrl?: string | null;
  linkSecondaryLabel?: string | null;
  linkSecondaryUrl?: string | null;
  primaryCta?: {
    url: string;
    label: string;
  } | null;
  secondaryCta?: {
    url: string;
    label: string;
  } | null;
  openLinksInNewTab?: boolean | null;
  openCtasInNewTab?: boolean | null;
  inlineButtons?: boolean | null;
};

export type ImageOptimisedResponse = {
  largeImage: {
    url: string;
    title: string;
    description: string | null;
  };
  smallImage: {
    url: string;
  };
} | null;

export type VersatileCardResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  name: string;
  htmlId?: string;
  backgroundColor?: string | null;
  image: {
    title: string;
    url: string;
    description: string | null;
  } | null;
  imageAlt: string | null;
  imagePosition: string;
  imageHeight: number;
  imageMobileHeight: number;
  imageWidth: string | null;
  imageContained: boolean | null;
  imageOptimised: ImageOptimisedResponse;
  headingOne: string | null;
  headingTwo: string | null;
  headingThree: string | null;
  headingFour: string | null;
  headingFive: string | null;
  bodyContent: string | null;
  spaceBetweenHeadingAndBody: boolean | null;
  textColor: TEXT_COLOR | null;
  textAlign: TEXT_ALIGN | null;
  linkPrimary?: string | null;
  linkLabelPrimary?: string | null;
  linkSecondary?: string | null;
  linkLabelSecondary?: string | null;
  ctaFullWidth: boolean | null;
  imageButtonPrimary: {
    url: string;
    description: string;
    height: number;
    width: number;
  } | null;
  imageButtonPrimaryUrl: string | null;
  imageButtonSecondary: {
    url: string;
    description: string;
    height: number;
    width: number;
  } | null;
  imageButtonSecondaryUrl: string | null;
  youtubeId: string | null;
  youtubeTitle: string | null;
  youtubeAutoPlay: boolean | null;
  youtubeVideoHeight: string | null;
  partner: PartnerResponse;
  partnerVariant: string | null;
  partnerTextSize: string | null;
  partnerLogoSize: string | null;
  partnerSeparatorLine: boolean | null;
  showPartnerFavouriteToggle: boolean | null;
  openCtasInNewTab?: boolean | null;
  openLinksInNewTab?: boolean | null;
  borderless: boolean | null;
  buttonsCollection?: { items: ButtonResponse[] };
  cardIsPrimaryLink: boolean | null;
};

export enum IMAGE_POSITION {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export enum DYNAMIC_VERSATILE_CARD_EXTERNAL_SOURCE {
  TopPicks = 'Top Picks',
  CollinsonBanners = 'Collinson Banners',
}

export interface DynamicVersatileCardContentfulResponse {
  contentType: CONTENT_TYPES.DynamicVersatileCard;
  sys: {
    id: string;
  };
  title: string;
  externalSource: DYNAMIC_VERSATILE_CARD_EXTERNAL_SOURCE;
  maximumNumberOfCards: number | null;
}

export interface VersatileCardContentfulResponse {
  contentType: string;
  name: string | null;
  sys: {
    id: string;
  };
  htmlId: string | null;
  backgroundColor: string | null;
  image: {
    title: string;
    url: string;
    description: string | null;
  } | null;
  imageAlt: string | null;
  imagePosition: string | null;
  imageHeight: number | null;
  imageMobileHeight: number | null;
  imageWidth: number | null;
  imageContained: boolean | null;
  imageOptimised: ImageOptimisedResponse;
  headingOne: string | null;
  headingTwo: string | null;
  headingThree: string | null;
  headingFour: string | null;
  headingFive: string | null;
  bodyContent: string | null;
  textAlign: TEXT_ALIGN;
  cardIsPrimaryLink: boolean | null;
  linkPrimary: string | null;
  linkLabelPrimary: string | null;
  linkSecondary: string | null;
  linkLabelSecondary?: string | null;
  ctaFullWidth: boolean | null;
  imageButtonPrimary: {
    url: string;
    description: string | null;
    height: number;
    width: number;
  } | null;
  imageButtonPrimaryUrl: string | null;
  imageButtonSecondary: {
    url: string;
    description: string | null;
    height: number;
    width: number;
  } | null;
  imageButtonSecondaryUrl: string | null;
  youtubeId: string | null;
  youtubeVideoHeight: string | null;
  youtubeTitle: string | null;
  youtubeAutoPlay: string | null;
  partner: ContentfulMerchant[] | null;
  partnerVariant: string | null;
  partnerTextSize: string | null;
  partnerLogoSize: string | null;
  partnerSeparatorLine: boolean | null;
  showPartnerFavouriteToggle: boolean | null;
  openLinksInNewTab: boolean | null;
  openCtasInNewTab: boolean | null;
  borderless: boolean | null;
  buttonsCollection: { items: ButtonResponse[] };
}

export interface HydratedVersatileCard
  extends Omit<VersatileCardContentfulResponse, 'partner'> {
  partner: HydratedPartner[];
}

export enum SECTION_VISIBILITY_FOR_SESSION {
  Both = 'Both (Logged in & out)',
  LoggedIn = 'Logged in',
  LoggedOut = 'Logged out',
}

export type ArticleStoryResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  name: string;
  htmlId?: string;
  imageAlt: string | null;
  youtubeId: string | null;
  youtubeTitle: string | null;
  youtubeAutoPlay: boolean | null;
  image: {
    title: string;
    url: string;
  } | null;
  content: TitleCopyCtaBlockResponse | null;
};

export type SuperheroResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  partner: PartnerResponse;
  titleCopyCtaBlock: TitleCopyCtaBlockResponse | null;
  heroImage: {
    largeImage: {
      url: string;
      title: string;
      description: string;
    };
    smallImage: {
      url: string;
    };
  } | null;
  imageHeight?: number | null;
  imageObjectPosition: string | null;
  youtubeId: string | null;
  youtubeTitle: string | null;
  youtubeAutoPlay: boolean | null;
  youtubeVideoHeight: string | null;
  titleCopyCtaBlockJustifyContent?: string;
  titleCopyCtaBlockAlignItems?: string;
  titleCopyCtaBlockMaxWidth?: string | null;
  titleCopyCtaBlockPaddingTop?: string;
  titleCopyCtaBlockPaddingBottom?: string;
  titleCopyCtaBlockPaddingLeft?: string;
  titleCopyCtaBlockPaddingRight?: string;
  opacityRgba?: string;
};

export type AccordionResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  name: string;
  title: string;
  body: string;
  isOpen?: boolean;
};

export type ItemsOptions =
  | TitleCopyCtaBlockResponse
  | ArticleStoryResponse
  | VersatileCardResponse
  | PartnerListResponse
  | AccordionResponse
  | CardResponse;

export type ContentRowResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  isRow: boolean;
  isCarousel: boolean;
  carouselAutoPlay?: boolean;
  carouselPresentation: string | null;
  contentSeparators?: boolean | null;
  extraPaddingBottom?: string | null;
  itemsCollection: {
    items: ItemsOptions[];
  };
  alignContent?: 'left' | 'center' | 'right';
};

export type SectionResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  ariaLabel?: string;
  visibility: SECTION_VISIBILITY_FOR_SESSION | null;
  backgroundColor: null | string;
  backgroundImage?: {
    title?: string | null;
    url?: string | null;
  } | null;
  isFullBleed?: boolean | null;
  paddingTop?: string | null;
  paddingBottom?: string | null;
  contentCollection: {
    items: ContentRowResponse[];
  };
};

export interface FinancialSectionData {
  sectionFinancialCategoryTier: string[] | null;
  userFinancialCategoryTier: string | null;
}

export type HydratedSectionResponse = SectionResponse & {
  isLoggedIn?: boolean;
  financialSectionData: FinancialSectionData;
};

export type FinancialServicesBannerResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  fsCategory: string;
  fsCategoryTier: string;
  bannerContent: VersatileCardResponse;
};

export type SectionsResponse = {
  items: HydratedSectionResponse[];
};

type CTAProperties = {
  url: string;
  label: string | null;
};

export type TitleCopyCtaBlockFormatted = {
  id: string;
  htmlId?: string | null;
  contentType: string;
  name: string | null;
  headingOne?: string | null;
  headingOneColor?: string | null;
  headingOneTextAlign?: string | null;
  headingTwo?: string | null;
  headingTwoColor?: string | null;
  headingTwoTextAlign?: string | null;
  description: string | null;
  descriptionColor: string | null;
  descriptionTextAlign: string;
  overrideDescriptionFontSize: string;
  linkPrimary: CTAProperties;
  linkSecondary: CTAProperties;
  openLinksInNewTab?: boolean | null;
  primaryCta: CTAProperties;
  secondaryCta: CTAProperties;
  openCtasInNewTab?: boolean | null;
  inlineButtons?: boolean | null;
  isFullBleed?: boolean | null;
};

export type VersatileCardFormatted = {
  id: string;
  htmlId?: string | null;
  backgroundColor?: string | null;
  contentType?: string;
  content: {
    headingOne?: string | null;
    headingTwo?: string | null;
    headingThree?: string | null;
    headingFour?: string | null;
    headingFive?: string | null;
    textAlign?: TEXT_ALIGN;
    textColor?: TEXT_COLOR;
    body?: string | null;
    partner?: PartnerTileProperties | null;
    showPartnerFavouriteToggle?: boolean | null;
  };
  image: {
    src: string;
    alt: string;
    position: IMAGE_POSITION;
    height: number;
    mobileHeight: number;
    width: string | null;
    contained: boolean;
    optimised: VersatileCardImageOptimised;
  };
  links?: {
    linkPrimary?: string | null;
    linkLabelPrimary: string | null;
    linkSecondary: string | null;
    linkLabelSecondary: string | null;
    openLinksInNewTab?: boolean | null;
    cardIsPrimaryLink: boolean | null;
  };
  ctas?: {
    ctaFullWidth: boolean;
    openCtasInNewTab?: boolean | null;
  };
  youtube: YoutubeEmbedBaseProperties | null;
  borderless: boolean;
  spaceBetweenHeadingAndBody: boolean;
  buttonsCollection?: ButtonBodyProperties[] | null;
};

export type ArticleStoryFormatted = {
  id: string;
  htmlId?: string | null;
  contentType: string;
  imageAlt: string | null;
  imageSrc: string | null;
  youtubeId: string | null;
  youtubeTitle: string | null;
  youtubeAutoPlay: boolean | null;
  content: TitleCopyCtaBlockFormatted | null;
};

export type SuperheroFormatted = {
  id: string;
  contentType: string;
  titleCopyCtaBlock: TitleCopyCtaBlockFormatted | null;
  heroImage: {
    largeImage: {
      url: string;
      title: string | null;
      description: string | null;
    };
  } | null;
  imageHeight: number;
  imageObjectPosition: string;
  partner: PartnerResponse;
  youtubeId: string | null;
  youtubeTitle: string | null;
  youtubeAutoPlay: boolean | null;
  youtubeVideoHeight: string | null;
  titleCopyCtaBlockJustifyContent: string;
  titleCopyCtaBlockAlignItems: string;
  titleCopyCtaBlockMaxWidth?: string | null;
  titleCopyCtaBlockPaddingTop: string;
  titleCopyCtaBlockPaddingBottom: string;
  titleCopyCtaBlockPaddingLeft: string;
  titleCopyCtaBlockPaddingRight: string;
  opacityRgba: string;
};

export type AccordionFormatted = {
  id: string;
  contentType: string;
  title: string;
  body: string;
  isOpen?: boolean;
};

export type ContentfulMerchant = {
  mechanicId: number;
  name: string;
  logoSrc: string;
  slug: string;
  override: {
    heroSrc: string;
  };
};

export type HydratedPartnerItem = {
  partnerId: number;
  mechanicId: number;
  slug: string;
  destinationUrl: string;
  logoSrc: string;
  heroSrc: string;
  name: string;
  rate: string;
  wasRate: string;
  noOfCollectionMethods: number;
  isSpeedyAwarding?: boolean;
};

export type PartnerListResponse = {
  contentType: string;
  sys: {
    id: string;
  };
  listType: 'col2' | 'col3' | 'col4' | 'col5' | 'col6';
  tileType: string;
  partnerVariant: string;
  merchants: ContentfulMerchant[];
  partners: HydratedPartnerItem[];
};

export type PartnerTileSimple = {
  name: string;
  slug: string;
  destinationUrl: string;
  altLogo: string;
  logoSrc: string;
  logoSize: LOGO_SIZE;
  rate: string;
  wasRate: string;
  isSpeedyAwarding?: boolean;
  speedyAwardText?: string;
};

export type MicrocopyGPT = {
  merchant_ratewas?: string;
  merchant_rateupto?: string;
  merchant_ratefrom?: string;
  speedyaward_title?: string;
  favourites_button_addtofavourites?: string;
  favourites_button_removefromfavourites?: string;
  shopnow_loggedinctalabel?: string;
  general_closelabel?: string;
};

export enum FLEX_POSITIONS {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export enum FORMATTER_METRICS {
  UNDEFINED_SECTIONS = 'sections.undefined',
}

type ImageButtonResponseProperties = {
  url: string;
  alt: string;
  height?: number;
  width?: number;
};

export type ButtonResponse = {
  sys: {
    id: string;
  };
  label?: string;
  url: string;
  alignIcon?: 'start' | 'end' | 'top' | 'bottom';
  iconName?: keyof typeof ICON_VARIANT;
  size: keyof typeof SIZE;
  developerRef?: string;
  openLinksInNewTab?: boolean;
  image?: ImageButtonResponseProperties;
  iconColor?: 'primary' | 'white' | 'black';
  textColor?: 'primary' | 'white' | 'black';
  textSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
} & (
  | {
      variant: 'Primary' | 'Secondary' | 'Tertiary' | 'Danger' | 'Ghost';
    }
  | {
      variant: 'Image';
      image: ImageButtonResponseProperties;
    }
);

export type MemberGetMemberResponse = {
  contentType: string;
  name: string;
  ariaLabel?: string;
  termsLabel: string;
  termsContent?: string;
  termsExtraContent?: string;
};

export type FilteredPartnersItems = {
  merchantlistfilterable_sort_mostpopular: string;
  loadmore_loadmore: string;
  loadmore_of: string;
};

export type HydratedPartner = {
  variant: string | null;
  textSize?: string | null;
  logoSize?: string | null;
  separator: boolean | null;
  name: string;
  slug?: string;
  logoSrc?: string;
  partnerId?: number;
  mechanicId?: number | null;
  wasRate?: string | null;
  rate?: string | null;
  isSpeedyAwarding?: boolean | null;
  noOfCollectionMethods?: number;
} | null;

export type GetTopPicksParameters = {
  session: Session | null | undefined;
  b2bToken: string;
  market: Market;
  maxPartners?: number | null;
};

export type GetCollinsonBannersParameters = {
  b2bToken: string;
  market: Market;
  maxPartners?: number | null;
};

export type HandleDynamicVersatileCardParameters = {
  session: Session | null | undefined;
  b2bToken: string;
  market: Market;
  dynamicVersatileCard: DynamicVersatileCardContentfulResponse;
};

export type BannersCarouselCollinsonData = {
  default: string;
  type: string;
  placement: string;
  device: string;
  position: string;
  tag: string;
  actual_to: string;
  actual_from: string;
  url: string;
  image: string;
  id: string;
  object_id: string;
  alt: string;
};

export type BannersCarouselCollinsonResponse = Response<
  BannersCarouselCollinsonData[]
> &
  ErrorResponse;

export type CardResponse = {
  icon: string;
  textColour: 'primary' | 'white' | 'black';
  backgroundColour: string;
  variant: CARD_VARIANT;
  image: {
    title: string;
    url: string;
    description: string;
  };
  textBlock: TextBlockTypes;
  cardLink?: string;
  isStorybook?: boolean;
};

export type ContainerResponse = {
  sys: {
    id: string;
  };
  contentType: CONTENT_TYPES.Container;
  variant: CONTAINER_VARIANT;
  layout: CONTAINER_ROW_LAYOUT;
  alignContent: CONTAINER_ALIGN_CONTENT;
  componentsCollection: {
    items: CardResponse[];
  };
};

import type {
  SessionStorePayload,
  SessionStore,
  ConfigParameters,
} from '@auth0/nextjs-auth0';
import { isAviosOpco } from '@/utils/opco-utils';
import { AVIOS_HOME, AVIOS_PREFIX } from '@/constants';
import RedisProvider, { Redis } from '../redis';
import { Market } from '../../models/market/market.types';
import getEnvironmentVariable from '../../utils/get-environment-variable';
import logger from '../../utils/logger';
import { audienceUrls, audienceScopes } from './auth0.constants';
import { AUDIENCES } from './auth0.types';

class Store implements SessionStore {
  public static singleton = new Store();

  private redis: Redis;

  constructor() {
    this.redis = RedisProvider.getInstance();
  }

  async get(id: string) {
    const data = await this.redis.get(id);
    return data ? JSON.parse(data) : null;
  }

  async set(id: string, value: SessionStorePayload) {
    const expiryMs = value.header.exp * 1000;
    await this.redis.set(id, JSON.stringify(value), 'PX', expiryMs);
  }

  async delete(id: string) {
    await this.redis.del(id);
  }
}

export const getAuth0AuthorizationParametersForAudience = (
  audience: AUDIENCES,
) => ({
  audience: audienceUrls[audience],
  scope: audienceScopes[audience],
});

export const getAuth0BaseConfig = (
  market: Market,
): Partial<ConfigParameters> => {
  const baseURL =
    getEnvironmentVariable('AUTH0_BASE_URL', market) ?? market?.baseUrl;
  const baseUrlWithoutTrailingSlash = baseURL.replace(/\/$/, '');
  logger.info(
    `baseURL: ${baseURL}, callback: "${AVIOS_PREFIX}${getEnvironmentVariable(
      'AUTH0_CALLBACK',
    )}"`,
  );

  return {
    secret: getEnvironmentVariable('AUTH0_SECRET'),
    baseURL,
    issuerBaseURL: getEnvironmentVariable('AUTH0_ISSUER_BASE_URL', market),
    clientID: getEnvironmentVariable('AUTH0_CLIENT_ID', market),
    clientSecret: getEnvironmentVariable('AUTH0_CLIENT_SECRET', market),
    httpTimeout: 30_000,
    session: {
      store: Store.singleton,
      cookie: {
        // A mitigtion for CSRF attacks.
        // strict would be better, but it breaks the login flow due to the way auth0 handles cookies.
        sameSite: 'lax',
      },
    },
    ...(isAviosOpco(market.opCoId) && {
      routes: {
        postLogoutRedirect: `${baseUrlWithoutTrailingSlash}${AVIOS_HOME}/`,
        callback: `${AVIOS_PREFIX}${getEnvironmentVariable('AUTH0_CALLBACK')}`,
      },
    }),
  };
};
